import axios from "axios";
import * as constants from "../constants/constants";

export const axiosInstance = axios.create({
    baseURL: constants.BACKEND_URL + "/api",
    headers: {
        'Content-Type': 'application/json'
    }
});

export const initializeUser = (tgId, username) => {
    return axiosInstance.post('/user/loginOrRegister', {tgId, username})
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const getUsers = () => {
    return axiosInstance.post('/user/all')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const getUserProblemsByTgId = (tgId) => {
    return axiosInstance.post(`/problem/my/${tgId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const addProblem = (problemJson) => {
    return axiosInstance.post('/problem/add', problemJson, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const getRandomProblem = (tgId) => {
    return axiosInstance.post(`/problem/random/${tgId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const getDeferredProblems = (tgId) => {
    return axiosInstance.post(`/problem/deferred/${tgId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const deferProblem = (tgId, problemId) => {
    return axiosInstance.post(`/problem/defer`, {tgId, problemId})
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const getProblemById = (problemId) => {
    return axiosInstance.post(`/problem/${problemId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const addReview = (tgId, problemId, text, result) => {
    return axiosInstance.post('/review/add', {tgId, problemId, text, result})
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}