import React, {useEffect, useState} from 'react';
import './StatisticsPage.css';
import * as client from "../../../utils/client";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {Link} from 'react-router-dom';
import {useTelegram} from "../../../hooks/useTelegram";
import {AiFillDislike, AiFillLike} from "react-icons/ai";

const StatisticsPage = ({user}) => {
    const {telegram} = useTelegram();
    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(null);

    useEffect(() => {
        telegram.BackButton.hide();
    }, [telegram]);

    useEffect(() => {
        client.getUsers()
            .then((userJson) => {
                setUsers(userJson);
                setLoading(false);
            })
            .catch(err => {
                console.log('Error while fetching users', err);
            });
    }, []);

    if (loading) {
        return <LoadingSpinner/>;
    }

    const handleUserClick = (userId) => {
        setSelectedUserId(prevId => prevId === userId ? null : userId);
    };

    return (
        <div className='page-container'>
            {users.map((user) => (
                <div key={user.tgId} className='user-card'>
                    <div className='user-header' onClick={() => handleUserClick(user.tgId)}>
                        <p>
                            <strong>{user.username} | {user.problems.length}</strong>
                        </p>
                    </div>
                    {selectedUserId === user.tgId && (
                        <div className='task-list'>
                            {user.problems.map((problem) => (
                                <div key={problem.id} className='task-item'>
                                    <Link to={`/problem/${problem.id}`}>
                                        {problem.type}
                                        |
                                        <AiFillLike/> {problem.positiveVotesCount} / <AiFillDislike/> {problem.negativeVotesCount}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default StatisticsPage;
