import React, {useEffect, useState} from 'react';
import './ReviewPage.css';
import * as client from "../../../utils/client";

import {useTelegram} from "../../../hooks/useTelegram";
import * as constants from "../../../constants/constants";

const ReviewPage = ({user}) => {
    const {telegram} = useTelegram();
    const [showDeferredPool, setShowDeferredPool] = useState(false);
    const [deferredProblems, setDeferredProblems] = useState([]);
    const [showProblemModal, setShowProblemModal] = useState(false);
    const [selectedFromDeferredPool, setSelectedFromDeferredPool] = useState(false);

    const [problem, setProblem] = useState(null);
    const [reviewText, setReviewText] = useState('');

    useEffect(() => {
        client.getDeferredProblems(user.tgId)
            .then(deferredProblemJson => {
                setDeferredProblems(deferredProblemJson);
            })
            .catch(err => {
                console.log(err);
            });
    }, [user.tgId]);

    if (!user || (user.role !== "ADMIN" && user.role !== "REVIEWER")) {
        return <div className='page-container'>
            Empty
        </div>
    }

    const getRandomProblem = () => {
        client.getRandomProblem(user.tgId)
            .then(randomProblemJson => {
                if (!randomProblemJson) {
                    alert('Задачи кончились!');
                } else {
                    setProblem(randomProblemJson);
                    setShowProblemModal(true);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleDeferredProblemSelect = (problem) => {
        setProblem(problem);
        setShowDeferredPool(false);
        setSelectedFromDeferredPool(true);
        setShowProblemModal(true);
    };

    const addReview = (result) => {
        client.addReview(user.tgId, problem.id, reviewText, result)
            .then(() => {
                setDeferredProblems(prevDeferredProblems =>
                    prevDeferredProblems.filter(p => p.id !== problem.id)
                );
                setProblem(null);
                setShowProblemModal(false);
                setReviewText('');
            })
            .catch(err => {
                console.log(err);
            });
    };

    const deferProblem = () => {
        client.deferProblem(user.tgId, problem.id)
            .then(() => {
                setDeferredProblems(prevDeferredProblems => {
                    const existingIndex = prevDeferredProblems.findIndex(p => p.id === problem.id);

                    if (existingIndex >= 0) {
                        const updatedProblems = [...prevDeferredProblems];
                        updatedProblems[existingIndex] = problem;
                        return updatedProblems;
                    } else {
                        return [...prevDeferredProblems, problem];
                    }
                });

                setShowProblemModal(false);
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <div className='page-container'>
            <div className='review-page-header-buttons'>
                <button className='add-problem-button' onClick={getRandomProblem}>
                    Get random problem
                </button>
                <button className='add-problem-button' onClick={() => setShowDeferredPool(true)}>
                    Select from deferred problem's pool
                </button>
            </div>

            {showDeferredPool && (
                <div className='modal'>
                    <div className='modal-content'>
                        <h2>Select a deferred problem</h2>
                        <ul className='problems-list'>
                            {deferredProblems && deferredProblems.length > 0
                                ? deferredProblems.map(problem => (
                                    <li key={problem.id} className='problem-card'
                                        onClick={() => handleDeferredProblemSelect(problem)}>
                                        <div className='problem-theme'>{problem.type} | {problem.theme}</div>
                                    </li>))
                                : <p>No deferred problems yet.</p>
                            }
                        </ul>
                        <button className='close-button' onClick={() => setShowDeferredPool(false)}>Close</button>
                    </div>
                </div>
            )}

            {showProblemModal && problem && (
                <div className='modal'>
                    <div className='modal-content'>
                        <p>{problem.name}</p>
                        <div className='problem-media'>
                            <img
                                src={constants.BACKEND_URL + "/api/files/" + problem.illustrationImageUrl}
                                alt="Problem Illustration"
                                className='problem-image'
                            />
                        </div>
                        <textarea
                            minLength={5}
                            maxLength={100}
                            value={reviewText}
                            required={true}
                            onChange={(e) => setReviewText(e.target.value)}
                            placeholder='Write your review here...'
                        />
                        <div className='review-buttons'>
                            <button onClick={() => addReview('ACCEPTED')}>Accept</button>
                            <button onClick={() => addReview('REJECTED')}>Reject</button>
                            {!showDeferredPool && !selectedFromDeferredPool &&
                                <button onClick={deferProblem}>Defer</button>}
                        </div>
                        <button className='close-button fullwidth'
                                onClick={() => {
                                    setShowProblemModal(false);
                                    setReviewText('');
                                }}>Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReviewPage;
