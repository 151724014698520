import React, {useState} from 'react';
import './AddProblemForm.css';
import * as client from '../../../utils/client';
import confetti from 'canvas-confetti';
import imageCompression from "browser-image-compression";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

const AddProblemForm = ({user, onClose, onProblemAdded}) => {
    const [problemJson, setProblemJson] = useState(ProblemCredentialsInitData(user));
    const [validationErrors, setValidationErrors] = useState({});
    const [isPending, setIsPending] = useState(false);

    const updateCoAuthors = (index, e) => {
        const coAuthors = [...problemJson.coAuthors];
        coAuthors[index] = e.target.value;
        setProblemJson((previousState) => ({
            ...previousState,
            coAuthors: coAuthors,
        }));
    };

    const addCoAuthor = () => {
        if (problemJson.coAuthors.length < 3) {
            setProblemJson((previousState) => ({
                ...previousState,
                coAuthors: [...previousState.coAuthors, ''],
            }));
        } else {
            alert("You can add up to 3 co-authors.");
        }
    };

    const removeCoAuthor = (index) => {
        setProblemJson((previousState) => ({
            ...previousState,
            coAuthors: previousState.coAuthors.filter((_, i) => i !== index),
        }));
    };

    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 10,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Error compressing image:", error);
            return file;
        }
    };

    const changeIllustrationImage = async (e) => {
        let file = e.target.files[0];
        const validImageTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/jpg', 'image/jfif'];

        if (file && validImageTypes.includes(file.type)) {
            if (file.size > 10 * 1024 * 1024) {
                file = await compressImage(file);
            }
            setProblemJson((previousState) => ({
                ...previousState,
                illustrationImage: file,
            }));
        } else {
            alert('Please upload a valid image file (PNG, JPEG, HEIC, etc.)');
        }
    };

    const changeSolutionPdf = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            if (file.size > 25 * 1024 * 1024) {
                alert('PDF size cannot exceed 10MB');
            } else {
                setProblemJson((previousState) => ({
                    ...previousState,
                    solutionPdf: file,
                }));
            }
        } else {
            alert('Please upload a valid PDF file');
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!problemJson.theme) errors.theme = "Theme is required";
        if (!problemJson.type) errors.type = "Type is required";

        if (!problemJson.illustrationImage) {
            errors.illustrationImage = "Illustration Image is required";
        } else if (problemJson.illustrationImage.size > 10 * 1024 * 1024) {
            errors.illustrationImage = "Illustration Image size cannot exceed 10MB";
        }

        if (!problemJson.solutionPdf) {
            errors.solutionPdf = "PDF Solution is required";
        } else if (problemJson.solutionPdf.size > 25 * 1024 * 1024) {
            errors.solutionPdf = "PDF Solution size cannot exceed 25MB";
        }

        return errors;
    };

    const addProblem = (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        setIsPending(true);

        const problemJsonData = new FormData();
        problemJsonData.append('tgId', user.tgId);
        problemJsonData.append('name', problemJson.name);
        problemJsonData.append('theme', problemJson.theme);
        problemJsonData.append('type', problemJson.type);
        problemJsonData.append('illustrationImage', problemJson.illustrationImage);
        problemJsonData.append('solutionPdf', problemJson.solutionPdf);
        problemJsonData.append('coAuthors', problemJson.coAuthors.join('\n'));

        client.addProblem(problemJsonData)
            .then((addedProblem) => {
                confetti({
                    particleCount: 100,
                    spread: 70,
                    origin: {y: 0.6}
                });
                onProblemAdded(addedProblem);
                onClose();
                setProblemJson(ProblemCredentialsInitData(user));
            })
            .catch(error => {
                alert(error.response.data);
            })
            .finally(() => {
                setIsPending(false);
            });
    };

    return (
        <div className='modal-content'>
            {isPending
                ? <LoadingSpinner/>
                : <>
                    <h3>Add New Problem</h3>
                    <form onSubmit={addProblem}>
                        <div className='form-group'>
                            <label htmlFor='name'>Name:</label>
                            <input id='name'
                                   type='text'
                                   name='name'
                                   value={problemJson.name}
                                   onChange={(e) =>
                                       setProblemJson({...problemJson, name: e.target.value})
                                   }
                                   placeholder="Имя задачи"
                            />
                            {validationErrors.theme && <span className="error">{validationErrors.theme}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='theme'>Theme:</label>
                            <select id='theme'
                                    name='theme'
                                    value={problemJson.theme}
                                    onChange={(e) =>
                                        setProblemJson({...problemJson, theme: e.target.value})
                                    }
                                    required>
                                {Object.entries(ProblemTheme).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                            {validationErrors.theme && <span className="error">{validationErrors.theme}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='type'>Type:</label>
                            <select id='type'
                                    name='type'
                                    value={problemJson.type}
                                    onChange={(e) =>
                                        setProblemJson({...problemJson, type: e.target.value})}
                                    required>
                                {Object.entries(ProblemType).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                            {validationErrors.type && <span className="error">{validationErrors.type}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='illustrationImage'>Illustration Image:</label>
                            <input type='file'
                                   id='illustrationImage'
                                   name='illustrationImage'
                                   onChange={changeIllustrationImage}
                                   accept='image/*'
                            />
                            {validationErrors.illustrationImage &&
                                <span className="error">{validationErrors.illustrationImage}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='solutionPdf'>PDF Solution:</label>
                            <input type='file'
                                   id='solutionPdf'
                                   name='solutionPdf'
                                   onChange={changeSolutionPdf}
                                   accept='application/pdf'
                            />
                            {validationErrors.solutionPdf &&
                                <span className="error">{validationErrors.solutionPdf}</span>}
                        </div>
                        <div className='form-group'>
                            <label>Co-Authors (optional):</label>
                            {problemJson.coAuthors.map((coAuthor, index) => (
                                <div key={index} className="coauthor-input-container">
                                    <input type='text'
                                           name={`coAuthor-${index}`}
                                           value={coAuthor}
                                           onChange={(e) => updateCoAuthors(index, e)}
                                           maxLength='255'
                                           placeholder="ФИО со-автора"
                                    />
                                    <button type="button" className="remove-coauthor-button"
                                            onClick={() => removeCoAuthor(index)}>-
                                    </button>
                                </div>
                            ))}
                            <button type="button" className="add-coauthor-button" onClick={addCoAuthor}>+</button>
                        </div>
                        <div className='form-buttons'>
                            <button type='submit' className='submit-button'>Submit</button>
                            <button type='button' className='close-button' onClick={onClose}>Close</button>
                        </div>
                    </form>
                </>
            }
        </div>
    );
};

const ProblemCredentialsInitData = (user) => ({
    tgId: user.tgId,
    name: null,
    theme: 'ALGEBRA',
    type: 'JUNIOR',
    illustrationImage: null,
    solutionPdf: null,
    coAuthors: []
});

const ProblemTheme = {
    ALGEBRA: 'Алгебра',
    GEOMETRY: 'Геометрия',
    NUMBER_THEORY: 'Теория чисел',
    COMBINATORICS: 'Комбинаторика'
};

const ProblemType = {
    JUNIOR: 'Младшие (5-7 кл.)',
    LIST: 'Перечень',
    CLASSIC: 'Классика'
};

export default AddProblemForm;
