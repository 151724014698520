import React, {useEffect} from 'react';
import './SettingsPage.css';
import {useTelegram} from "../../../hooks/useTelegram";

const SettingsPage = ({user}) => {
    const {telegram} = useTelegram();

    useEffect(() => {
        telegram.BackButton.hide();
    }, []);

    return (
        <div className='page-container'>
            SettingsPage Component
        </div>
    );
};

export default SettingsPage;
