import './App.css';
import {useTelegram} from "./hooks/useTelegram";
import React, {useEffect, useState} from "react";
import * as client from "./utils/client";
import NavBar from "./components/NavBar/NavBar";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ProblemsPage from "./components/page/ProblemsPage/ProblemsPage";
import ReviewPage from "./components/page/ReviewPage/ReviewPage";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import ProblemPage from "./components/page/ProblemPage/ProblemPage";
import SettingsPage from "./components/page/SettingsPage/SettingsPage";
import StatisticsPage from "./components/page/StatisticsPage/StatisticsPage";

function App() {
    const {telegram, telegramUser} = useTelegram();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        telegram.ready();
        telegram.expand();
        telegram.disableVerticalSwipes();
    }, [telegram]);

    useEffect(() => {
        if (telegramUser && !user) {
            client.initializeUser(telegramUser.id, telegramUser.username)
                .then(userJson => {
                    setUser(userJson);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Can't authorize user:", error.message);
                });
        }
    }, [telegramUser, user]);

    const isPrivilegeUser = (user) => {
        return user && (user.role === "ADMIN" || user.role === "REVIEWER");
    }

    if (loading) {
        return <LoadingSpinner/>
    }

    return (
        <Router>
            {isPrivilegeUser(user) && <NavBar user={user}/>}
            <Routes>
                <Route path='/' element={<ProblemsPage user={user}/>}/>
                <Route path='/problem/:problemId' element={<ProblemPage user={user}/>}/>
                <Route path='/settings' element={<SettingsPage user={user}/>}/>
                <Route path='/statistics' element={<StatisticsPage user={user}/>}/>
                {isPrivilegeUser(user) && <Route path='/review' element={<ReviewPage user={user}/>}/>}
            </Routes>
        </Router>
    );
}

export default App;
