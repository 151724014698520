import React, {useEffect, useState} from 'react';
import './ProblemPage.css';
import {useNavigate, useParams} from 'react-router-dom';
import * as client from '../../../utils/client';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {useTelegram} from "../../../hooks/useTelegram";
import * as constants from "../../../constants/constants";

const ProblemPage = ({user}) => {
    const {problemId} = useParams();
    const {telegram} = useTelegram();
    const navigate = useNavigate();
    const [problem, setProblem] = useState(null);
    const [loading, setLoading] = useState(true);

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (window.history.length > 1) {
            telegram.BackButton.show();
            telegram.BackButton.onClick(goBack);
        } else {
            telegram.BackButton.hide();
        }
        return () => {
            telegram.BackButton.offClick(goBack);
        };
    }, [telegram, navigate]);

    useEffect(() => {
        client.getProblemById(problemId)
            .then(problemJson => {
                setProblem(problemJson);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching problem:", error);
                setLoading(true);
            });
    }, [problemId]);

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className='page-container'>
            <div className='problem-details'>
                <p className='problem-theme'>{problem.theme} | {problem.type} </p>
                {problem.coAuthors && problem.coAuthors.length > 0 && (
                    <div className='problem-coauthors'>
                        <p>Co-Authors:</p>
                        {problem.coAuthors.split('\n').map((coAuthor, index) => (
                            <p key={index} className='coauthor-name'>{coAuthor}</p>
                        ))}
                    </div>
                )}
                <div className='problem-media'>
                    <img
                        src={constants.BACKEND_URL + "/api/files/" + problem.illustrationImageUrl}
                        alt="Problem Illustration"
                        className='problem-image'
                    />
                </div>
            </div>
        </div>
    );
};

export default ProblemPage;
