import React from 'react';
import './NavBar.css';
import {NavLink} from "react-router-dom";
import {CiChat2, CiCircleInfo, CiSettings, CiViewList} from "react-icons/ci";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";

const NavBarRouter = [
    {
        title: 'Problems',
        path: '/',
        icon: <CiViewList/>,
        cName: 'nav-text'
    },
    {
        title: 'Review',
        path: '/review',
        icon: <CiChat2/>,
        cName: 'nav-text'
    },
    {
        title: 'Settings',
        path: '/settings',
        icon: <CiSettings/>,
        cName: 'nav-text'
    },
    {
        title: 'Statistics',
        path: '/statistics',
        icon: <CiCircleInfo/>,
        cName: 'nav-text'
    },
];

const NavBar = () => {
    const [impactOccurred] = useHapticFeedback();

    return (<nav className='nav-menu'>
        <ul className='nav-menu-items'>
            {NavBarRouter.map((item, index) => (
                <li key={index} className={item.cName}>
                    <NavLink
                        onClick={() => {
                            impactOccurred('light');
                        }}
                        to={item.path}
                        className={({isActive}) => isActive ? 'nav-text active' : 'nav-text'}>
                        {item.icon}
                        <span>{item.title}</span>
                    </NavLink>
                </li>
            ))}
        </ul>
    </nav>);
};

export default NavBar;
